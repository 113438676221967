import request from '@/utils/request'

// 查询机器人列表
export function listRobot(query) {
    return request({
        url: '/business/robot/pageList',
        method: 'post',
        data: query
    })
}

// 查询机器人详细
export function getRobot(robotId) {
    return request({
        url: '/business/robot/view/' + robotId,
        method: 'get'
    })
}

//新增机器人
export function addRobot(data) {
    return request({
        url: '/business/robot/add',
        method: 'post',
        data: data
    })
}

//修改机器人
export function updateRobot(data) {
    return request({
        url: '/business/robot/edit',
        method: 'post',
        data: data
    })
}

//删除机器人
export function delRobot(robotId) {
    return request({
        url: '/business/robot/delete/' + robotId,
        method: 'delete'
    })
}

// 获取已连接的机器人
export function getRobotState() {
    return request({
        url: '/business/robot/getConnectedRobot',
        method: 'get'
    })
}

// 修改机器人运行状态
export function getRobotRunState(data) {
    return request({
        url: '/business/robot/changeRobotTaskState',
        method: 'post',
        data: data
    })
}

// 修改机器人连接状态
export function getRobotContactState(data) {
    return request({
        url: '/business/robot/changeRobotConnectionState',
        method: 'post',
        data: data
    })
}

// // 机器人连接状态
// export function RobotState() {
//     return request({
//         url: '/enum/business/robotConnectionEnum',
//         method: 'get'
//     })
// }

// 机器人连接状态置空
export function resetRobotConnectionState() {
    return request({
        url: '/business/robot/resetRobotConnectionState',
        method: 'get'
    })
}

// 地图连接状态置空
export function resetMapConnectionState() {
    return request({
        url: '/business/map/resetMapConnectionState',
        method: 'get'
    })
}

// 获取所有的机器人和地图信息
export function getRobotAndMap() {
    return request({
        url: '/business/robot/getRobotAndMap',
        method: 'get'
    })
}