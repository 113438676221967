import { login, getPermissions } from '@/api/login'
import { getUser, getToken, setToken, removeToken, getRefToken, setRefToken, removeRefToken, removeRobot, removeMap } from '@/utils/auth'

const user = {
    state: {
        token: getToken(),
        name: '',
        avatar: '',
        roles: [],
        permissions: []
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        }
    },

    actions: {

        //新的连接
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.username.trim()
            const password = userInfo.password
            return new Promise((resolve, reject) => {
                login(username, password).then(res => {
                    setToken(res.data.access_token)
                    setRefToken(res.data.refresh_token)
                    commit('SET_TOKEN', res[1])
                        // commit('SET_ROLES', res[0].data.nickName)
                        // commit('SET_PERMISSIONS', "*:*:*")
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('SET_ROLES', ["admin"])
                    // const avatar = require("@/assets/image/profile.jpg");
                const avatar = require("@/assets/image/user.png");
                commit('SET_AVATAR', avatar)

                // commit('SET_PERMISSIONS', ["*:*:*"])
                getPermissions(getUser()).then(res => {
                    if (res && res.code === "000000") { // 验证返回的roles是否是一个非空数组
                        commit('SET_PERMISSIONS', res.data)
                        resolve()
                    } else if (res === "认证错误") {
                        reject(res)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                // logout(state.token).then(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                commit('SET_PERMISSIONS', [])
                removeToken()
                removeRefToken()
                removeRobot()
                removeMap()
                resolve()
                    // }).catch(error => {
                    //     reject(error)
                    // })
            })
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                removeRefToken()
                removeRobot()
                removeMap()
                resolve()
            })
        }
    }
}

export default user