import variables from '@/assets/styles/element-variables.less'

const state = {
    theme: variables.theme,
    tagsView: true,
    fixedHeader: false,
    sidebarLogo: true
}

export default {
    namespaced: true,
    state
}