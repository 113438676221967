import { constantRoutes } from '@/router'
import { getRouters } from '@/api/login'
import { getUser } from '@/utils/auth'
import Layout from '@/layout/index'

const permission = {
    state: {
        routes: [],
        addRoutes: []
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes
            state.routes = constantRoutes.concat(routes)
        }
    },
    actions: {
        // 生成路由
        GenerateRoutes({ commit }) {
            return new Promise((resolve, reject) => {
                // 向后端请求路由数据
                getRouters(getUser()).then(res => {
                    if (res && res.code === "000000") { // 验证返回的roles是否是一个非空数组
                        var dataList = []
                        if (res.data.length > 0) {
                            // 转换获取的动态列表格式
                            res.data = Maketree(res.data, dataList)
                        }
                        const accessedRoutes = filterAsyncRouter(res.data)
                        accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
                        commit('SET_ROUTES', accessedRoutes)
                        resolve(accessedRoutes)
                    } else if (res === "认证错误") {
                        reject(res)
                    }
                })
            })
        }
    }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
    return asyncRouterMap.filter(route => {
        if (route.component) {
            // console.log(route.url)
            // if (route.url) {
            // Layout组件特殊处理
            if (route.component === 'Layout') {
                route.component = Layout
            } else {
                route.component = loadView(route.component)
            }
        }
        // }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })
}

// 动态转换格式
/** 
 * treelis = {
 *          "component": data[i].component ||"Layout",
            "hidden": false,
            "meta": {
                "title": "系统管理",
                "icon": "system"
            },
            //"redirect": "noRedirect",
            //"alwaysShow": true,
            "children": [],
            "name": "system",
            "path": "/system"
 * }
 * 
 * **/
function Maketree(data, list) {
    for (var i = 0; i < data.length; i++) {
        var treelis = {}
        treelis = {
            "component": data[i].component,
            "hidden": false,
            "meta": {
                "title": data[i].name,
                "icon": data[i].icon
            }
        }
        if (data[i].component == null) {
            treelis.component = "Layout"
        }
        if (data[i].children != null) {
            if (data[i].children.length > 0) {
                treelis.redirect = "noRedirect"
                treelis.alwaysShow = true
            }
            treelis.name = data[i].children[0].path.split('/')[0]
            treelis.path = "/" + data[i].children[0].path.split('/')[0]
            treelis.children = []
            treelis.children = Maketree(data[i].children, treelis.children)
        } else if (data[i].children == null && data[i].path != null) {
            treelis.name = data[i].path.split('/')[1]
            treelis.path = data[i].path.split('/')[1]
        } else {
            treelis.name = "404"
            treelis.path = "404"
        }
        list.push(treelis)
    }
    return list
}

export const loadView = (view) => { // 路由懒加载
    return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission