import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './assets/styles/element-variables.less'
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/styles/index.less' // global css
import '@/assets/styles/ruoyi.less' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
// 在入口文件中引入，全局异常捕获
import ErrorPlugin from '@/utils/errorPlugin'

import './assets/icons' // icon
import './permission' // permission control
// import { getConfigKey } from "@/api/system/config";
// import { parseTime, resetForm, addDateRange, selectDictLabel, download, handleTree } from "@/utils/ruoyi";, basUrl
import { parseTime, resetForm, addDateRange, download, handleTree, sleep, RABBITMQ_NAME, RABBITMQ_PASSWORD, RABBITMQ_URL } from "@/utils/ruoyi";
import Pagination from "@/components/Pagination";
import DialogTitle from "@/components/DialogTitle";

// 全局方法挂载
// Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
    // Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.sleep = sleep
Vue.prototype.RABBITMQ_NAME = RABBITMQ_NAME
Vue.prototype.RABBITMQ_PASSWORD = RABBITMQ_PASSWORD
Vue.prototype.RABBITMQ_URL = RABBITMQ_URL


Vue.prototype.msgSuccess = function(msg) {
    this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function(msg) {
    this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function(msg) {
    this.$message.info(msg);
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('DialogTitle', DialogTitle)

Vue.use(permission)
    /**
     * If you don't want to use mock-server
     * you want to use MockJs for mock api
     * you can execute: mockXHR()
     *
     * Currently MockJs will be used in the production environment,
     * please remove it before going online! ! !
     */
Vue.use(Element, {
    size: 'medium' // set element-ui default size
})
Vue.use(ErrorPlugin)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})