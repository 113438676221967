import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, setToken, removeToken, getRefToken, setRefToken, removeRefToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { sleep } from './ruoyi'
import { refLogin } from '@/api/login'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
    // 创建axios实例
const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout: 1000 * 60 * 10
    })
    // request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.status || 200;
        // 获取错误信息
        const message = errorCode[code] || res.data.msg || errorCode['default']
        if (code === 200 && ((res.data.code === "A0230" && res.data.msg !== "网络错误" && message !== "抱歉，您没有访问权限") || (res.data.msg && res.data.msg.indexOf("token") !== -1))) {
            if (message.indexOf("认证错误") !== -1) {
                var data = {
                    refreshToken: getRefToken()
                }
                refLogin(data).then(result => {
                    if (result && result.code === "000000") {
                        setToken(result.data.access_token)
                        setRefToken(result.data.refresh_token)
                    }
                })
                return message
            } else {
                Message({
                    message: "token过期。请等待重新跳转登录页！！！",
                    type: 'warning'
                });
                sleep(3000).then(() => {
                    removeToken()
                    removeRefToken()
                    store.dispatch('LogOut').then(() => {
                        location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
            }

        } else if (code === 500) {
            return Promise.reject(new Error(message))
        } else if (code !== 200) {
            Notification.error({
                title: message
            })
            return Promise.reject('error')
        } else if (code === 200 && res.data.code === undefined) {
            return res
        } else if (code === 200 && res.data.code !== "000000") {
            if (res.data.code === "C0300" && res.data.msg === "巡检结果在数据库不存在") {
                return res.data
            } else {
                Message({
                    message: res.data.msg,
                    type: 'error'
                })
            }
        } else {
            return res.data
        }
    },
    error => {
        var originalRequest = error.config;
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            originalRequest._retry = true
            return axios.request(originalRequest);
        } else {
            console.log('err' + error)
            return Promise.reject(error)
        }
    }
)

export default service