const control = {
    state: {
        limitAdd: false,
        chargeAdd: false,
        pointAdd: false,
        pointTree: true,
        panelID: '',
        imageDetailParams: {},
        finishPosition: {}
    },

    mutations: {
        SET_LIMIT: (state, limitAdd) => {
            state.limitAdd = limitAdd;
        },
        SET_CHARGE: (state, chargeAdd) => {
            state.chargeAdd = chargeAdd;
        },
        SET_POINT: (state, pointAdd) => {
            state.pointAdd = pointAdd;
        },
        SET_POINTTREE: (state, pointTree) => {
            state.pointTree = pointTree;
        },
        SET_PANELID: (state, panelID) => {
            state.panelID = panelID
        },
        SET_IMAGEPARAMS: (state, imageDetailParams) => {
            state.imageDetailParams = imageDetailParams
        },
        SET_MOUSEPOSE: (state, finishPosition) => {
            state.finishPosition = finishPosition
        },
    },

    actions: {}
}
export default control