import router from './router'
import store from './store'
import { Message, MessageBox } from 'element-ui'
import { getUser, getToken, getRobot, getMap, getRefToken, setToken, setRefToken } from '@/utils/auth'
import { getRobotState } from '@/api/robot/robot'
import { getMapState } from '@/api/robot/map'
import { refLogin } from '@/api/login'
import versionTood from '@/utils/versionUpdate'


const whiteList = ['/login', '/auth-redirect', '/bind', '/register']
    // let test = 0

router.beforeEach((to, from, next) => {
    //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
    versionTood.isNewVersion();
    if (getToken() && getUser()) {
        /* has token*/
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            if (store.getters.roles.length === 0) {
                Promise.all([store.dispatch('GetInfo'), store.dispatch('GenerateRoutes')]).then(res => {
                    router.addRoutes(res[1]) // 动态添加可访问路由表
                    if ((from.path === '/login' || from.path === '/') && to.path === '/index') {
                        next({ path: '/robot/content', replace: true })
                    } else {
                        next({...to, replace: true })
                    }
                }).catch(err => {
                    // if (err === "认证错误" ) {
                    var data = {
                        refreshToken: getRefToken()
                    }
                    refLogin(data).then(result => {
                            if (result && result.code === "000000") {
                                setToken(result.data.access_token)
                                setRefToken(result.data.refresh_token)
                                store.commit('SET_ROLES', [])
                                next({...to, replace: true })
                            }
                        })
                        // }
                })
            } else {
            // 本地http运行，云端https运行  注解如下
            /** 开始 */
                if (to.fullPath.indexOf('index') !== -1 || to.fullPath.indexOf('system') !== -1 || to.fullPath.indexOf('uppc') !== -1 || to.fullPath.indexOf('task') !== -1 || to.fullPath.indexOf('alarm') !== -1 || to.fullPath.indexOf('detail') !== -1) {
                    routerNum = 1
                    Promise.all([store.dispatch('GetContactRobot'), store.dispatch('GetContactMap')]).then(res => {
            /** 结束 */
                        next()
            /** 开始 */
                    }).catch(error => {
                        if (error === "认证错误") {
                            var data = {
                                refreshToken: getRefToken()
                            }
                            refLogin(data).then(result => {
                                if (result && result.code === "000000") {
                                    setToken(result.data.access_token)
                                    setRefToken(result.data.refresh_token)
                                }
                            })
                        }
                        next()
                    })
                } else {
                    Promise.all([store.dispatch('GetContactRobot'), store.dispatch('GetContactMap')]).then(res => {
                        routerNum = 1
                        next()
                    }).catch(error => {
                        if ((error === "机器人" && to.fullPath.indexOf('content') === -1) || (to.fullPath.indexOf('content') === -1 && getRobot() == null)) {
                            routerNum = 2
                            next({ path: '/robot/content' })
                            MessageBox.confirm(
                                '请先连接机器人', '提示', {
                                    confirmButtonText: '确定',
                                    showCancelButton: false,
                                    type: 'warning'
                                }
                            )
                        } else if (error === "地图" && to.fullPath.indexOf('map') === -1 && to.fullPath.indexOf('content') === -1) {
                            routerNum = 2
                            next({ path: '/robot/map' })
                            MessageBox.confirm(
                                '请先连接地图', '提示', {
                                    confirmButtonText: '确定',
                                    showCancelButton: false,
                                    type: 'warning'
                                }
                            )
                        } else if (error === "认证错误") {
                            var data = {
                                refreshToken: getRefToken()
                            }
                            refLogin(data).then(result => {
                                if (result && result.code === "000000") {
                                    setToken(result.data.access_token)
                                    setRefToken(result.data.refresh_token)
                                    next({...to, replace: true })
                                }
                            })
                        } else {
                            routerNum = 1
                            next()
                        }
                    })
                }
            /** 结束 */
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
        }
    }
})

router.onError((error) => {
    const pattern = /Loading (CSS chunk)? chunk-(\w)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        console.log("重新加载router")
        router.replace(targetPath);
    }
});