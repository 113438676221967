/** 
 * 参考文档：
 * https://www.ncnynl.com/archives/201709/2125.html
 */
import vue from 'vue'
import { Notification } from 'element-ui'
import eventemitter2 from 'eventemitter2'
import ROSLIB from 'roslib'
import NAV2D from 'nav2d'
import store from '../store'
import router from '../router'
import { getRobotContactState, resetRobotConnectionState, resetMapConnectionState } from '@/api/robot/robot'

vue.use(eventemitter2, NAV2D)

//变量
export const success = 200 //成功code值
var once = false;

//定义机器人需求值变量
// var mapConnectID, robotConnectID;
var timer, isLongPress;

/** roslib连接,监测机器人状态 */
/** 连接机器人 */
export function contactRosLink(rosIp, robotLink, checked, callback) {

    if (checked) {
        var data = {
            id: robotLink,
            robotConnectionState: "CONNECTION"
        }
        getRobotContactState(data).then(response => {
            if (response && response.code === "000000") {
                // commit('SET_ROBOTNAME', res.data.robotName)
            }
        })
        if (loadingFull !== undefined) {
            loadingFull.close()
            loadingFull = undefined
        }
        if (typeof callback === 'function') {
            callback('已连接')
        }
    } else {
        ros = new ROSLIB.Ros({
            url: 'ws://' + rosIp
        });

        ros.on('connection', function() {
            console.log('Connected to websocket server.');
            var data = {
                id: robotLink,
                robotConnectionState: "CONNECTION"
            }
            getRobotContactState(data).then(response => {
                if (response && response.code === "000000") {
                    // commit('SET_ROBOTNAME', res.data.robotName)
                }
            })
            if (loadingFull !== undefined) {
                loadingFull.close()
                loadingFull = undefined
            }
            if (typeof callback === 'function') {
                callback('已连接')
            }
        });

        ros.on('error', function(error) {
            console.log('Error connecting to websocket server: ', error);
        });

        ros.on('close', function() {
            console.log('Connection to websocket server closed.');
            ros = undefined
            if (!store.getters.checked) {
                Notification({
                    title: '提示',
                    message: '机器人连接断开',
                    duration: 5000
                });

                if (loadingFull !== undefined) {
                    loadingFull.close()
                    loadingFull = undefined
                }
                if (typeof callback === 'function') {
                    callback('未连接')
                }
                resetMapConnectionState().then(response => {
                    resetRobotConnectionState().then(response => {
                        // store.commit('SET_ROBOTNAME', "未连接")
                        store.dispatch('DeleteRobotLink', "未连接")
                        if (poseListener) {
                            poseListener.unsubscribe()
                        }
                        router.push({ path: '/robot/content' })
                    })
                })
            }
        });
    }

    // ros.connect('ws://' + rosIp + ':9091')
}


/* **  消息队列 */
/** 机器人控制 */
//按键按下方向
export function keyboardDownRabbitMQ(code) {
    var linearX = 0.0
    var angularZ = 0.0
    if (once) {
        // e.preventDefault();
    } else {
        once = true;
        switch (code) {
            case 87: //键盘w
                linearX = 0.3;
                break;
            case 65: //键盘A
                angularZ = 0.5;
                break;
            case 88: //键盘X
                linearX = -0.3;
                break;
            case 68: //键盘D
                angularZ = -0.5;
                break;
            case 81: //键盘Q
                linearX = 0.3;
                angularZ = 0.5;
                break;
            case 69: //键盘E
                linearX = 0.3;
                angularZ = -0.5;
                break;
            case 90: //键盘Z
                linearX = -0.3;
                angularZ = 0.5;
                break;
            case 67: //键盘C
                linearX = -0.3;
                angularZ = -0.5;
        }
        var data = JSON.stringify({
                sequence: 15,
                linearX: linearX,
                angularZ: angularZ
            })
            //假定500毫秒 等待触发mouseMove事件
            //若未触发则为长按事件
        isLongPress = setTimeout(timer, 500);
        //长按
        timer = setInterval(function() {
            client.publish({ destination: "/exchange/" + store.getters.robotSerial + "RobotControlExchange", body: data })
        }, 300)
    }
}
//按键抬起方向
export function keyboardUpRabbitMQ(code) {
    var linearX = 0.0
    var angularZ = 0.0
    once = false
    switch (code) {
        case 87: //键盘w
            linearX = 0.3;
            break;
        case 65: //键盘A
            angularZ = 0.5;
            break;
        case 88: //键盘X
            linearX = -0.3;
            break;
        case 68: //键盘D
            angularZ = -0.5;
            break;
        case 81: //键盘Q
            linearX = 0.3;
            angularZ = 0.5;
            break;
        case 69: //键盘E
            linearX = 0.3;
            angularZ = -0.5;
            break;
        case 90: //键盘Z
            linearX = -0.3;
            angularZ = 0.5;
            break;
        case 67: //键盘C
            linearX = -0.3;
            angularZ = -0.5;
    }
    var data = JSON.stringify({
        sequence: 15,
        linearX: linearX,
        angularZ: angularZ
    })
    client.publish({ destination: "/exchange/" + store.getters.robotSerial + "RobotControlExchange", body: data })
    clearInterval(timer)
    clearTimeout(isLongPress)
}
/** 云台控制 */
//按键按下方向
export function mouseDownRabbitMQ(direction) {
    var increZoom = 0
    var linearX = 0
    var angularX = 0
    if (once) {
        // e.preventDefault();
    } else {
        once = true;
        switch (direction) {
            case 'up':
                linearX = 8;
                break;
            case 'down':
                linearX = -8;
                break;
            case 'left':
                angularX = 8;
                break;
            case 'right':
                angularX = -8;
                break;
            case 'big':
                increZoom = 1
                break;
            case 'small':
                increZoom = -1;
                break;
        }
        var data = JSON.stringify({
                sequence: 16,
                linearX: linearX,
                angularX: angularX,
                increZoom: increZoom
            })
            //假定500毫秒 等待触发mouseMove事件
            //若未触发则为长按事件
        isLongPress = setTimeout(timer, 500);
        //长按
        timer = setInterval(function() {
            client.publish({ destination: "/exchange/" + store.getters.robotSerial + "PanTiltControlExchange", body: data })
        }, 300)
    }
}
//按键抬起方向
export function mouseUpRabbitMQ(direction) {
    var increZoom = 0
    var linearX = 0
    var angularX = 0
    once = false
    switch (direction) {
        case 'up':
            linearX = 2;
            break;
        case 'down':
            linearX = -2;
            break;
        case 'left':
            angularX = 2;
            break;
        case 'right':
            angularX = -2;
            break;
        case 'big':
            increZoom = 1
            break;
        case 'small':
            increZoom = -1;
            break;
    }
    var data = JSON.stringify({
        sequence: 16,
        linearX: linearX,
        angularX: angularX,
        increZoom: increZoom
    })
    client.publish({ destination: "/exchange/" + store.getters.robotSerial + "PanTiltControlExchange", body: data })
    clearInterval(timer)
    clearTimeout(isLongPress)
}

// 重置云台参数
export function panTiltResetRabbitMQ() {
    client.publish({ destination: "/exchange/" + store.getters.robotSerial + "PanTiltResetExchange", body: JSON.stringify({ sequence: 17 }) })
}

export function robotPose(callbackMsg) {
    let callback = function(message) {
        subscription.unsubscribe()
        var msg = JSON.parse(message.body)
        var pose = {
            orientation: {
                w: msg.orientationW,
                z: msg.orientationZ,
                x: msg.orientationX,
                y: msg.orientationY,

            },
            position: {
                x: msg.positionX,
                y: msg.positionY,
                z: msg.positionZ
            }
        }
        callbackMsg(pose)
    }
    let subscription = client.subscribe("/exchange/" + store.getters.robotSerial + "RobotPoseMessageExchange", callback);
}