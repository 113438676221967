import request from '@/utils/request'

// 查询地图列表
export function listMap(query) {
    return request({
        url: '/business/map/pageList',
        method: 'post',
        data: query
    })
}

// 查询地图详细
export function getMap(MapId) {
    return request({
        url: '/business/map/view/' + MapId,
        method: 'get'
    })
}

//新增地图
export function addMap(data) {
    return request({
        url: '/business/map/add',
        method: 'post',
        data: data
    })
}

//修改地图
export function updateMap(data) {
    return request({
        url: '/business/map/edit',
        method: 'post',
        data: data
    })
}

//删除地图
export function delMap(MapId) {
    return request({
        url: '/business/map/delete/' + MapId,
        method: 'delete'
    })
}

// 修改地图运行状态
// export function getMapRunState(data) {
//     return request({
//         url: '/business/robot/changeRobotTaskState',
//         method: 'post',
//         data: data
//     })
// }

// 获取已连接的地图
export function getMapState() {
    return request({
        url: '/business/map/getConnectedMap',
        method: 'get'
    })
}

// 修改地图连接状态
export function getMapContactState(data) {
    return request({
        url: '/business/map/changeMapConnectionState',
        method: 'post',
        data: data
    })
}