const TokenKey = 'Admin-Token'
const TokenRefKey = 'Refresh-Token'
const RobotId = 'RobotID'
const MapId = 'MapID'
const username = 'username'

export function getUser() {
    return localStorage.getItem(username)
}

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function getRefToken() {
    return localStorage.getItem(TokenRefKey)
}

export function setRefToken(token) {
    return localStorage.setItem(TokenRefKey, token);
}

export function removeRefToken() {
    return localStorage.removeItem(TokenRefKey)
}

export function getRobot() {
    return localStorage.getItem(RobotId)
}

export function setRobot(robotId) {
    return localStorage.setItem(RobotId, robotId)
}

export function removeRobot() {
    return localStorage.removeItem(RobotId)
}

export function getMap() {
    return localStorage.getItem(MapId)
}

export function setMap(mapId) {
    return localStorage.setItem(MapId, mapId)
}

export function removeMap() {
    return localStorage.removeItem(MapId)
}