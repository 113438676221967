import request from '@/utils/request'

// 获取系统参数
export function getSysParams() {
    return request({
        url: '/sys/param/getCheckedParams',
        method: 'get'
    })
}

// 获取系统参数列表
export function listSysParams() {
    return request({
        url: '/sys/param/paramsList',
        method: 'get',
    })
}

// 根据id获取系统参数
export function getSysParamsID(paramId) {
    return request({
        url: '/sys/param/getSysParams/' + paramId,
        method: 'get'
    })
}

// 切换系统参数
export function switchSysParams(paramId) {
    return request({
        url: '/sys/param/switchParam/' + paramId,
        method: 'get',
    })
}

// 更新系统参数
export function updateSysParams(data) {
    return request({
        url: '/sys/param/updateParam',
        method: 'post',
        data: data
    })
}

/** 以下为正在使用的接口 */

// 根据地图id获取系统参数
export function getSysParamsMapID(query) {
    return request({
        url: '/sys/param/view',
        method: 'post',
        data: query
    })
}

//含地图id的更新系统参数
export function updateSysParamsMap(data) {
    return request({
        url: '/sys/param/updateMapParam',
        method: 'post',
        data: data
    })
}

//含地图id的更新系统参数
export function addSysParamsMap(data) {
    return request({
        url: '/sys/param/addMapParam',
        method: 'post',
        data: data
    })
}