import request from '@/utils/request'

//机器人新的连接
export function login(username, password) {
    const data = {
        username,
        password
    }
    return request({
        url: '/auth/login',
        method: 'post',
        data: data
    })
}

// 获取路由
export const getRouters = (userName) => {
    return request({
        url: '/sys/permission/navMenuList/' + userName,
        method: 'get'
    })
}

// 获取用户的按钮权限
export function getPermissions(userName) {
    return request({
        url: '/sys/permission/btnPermissionList/' + userName,
        method: 'get',
    })
}

//机器人刷新Token
export function refLogin(data) {
    return request({
        url: '/auth/refreshToken',
        method: 'post',
        data: data
    })
}