const getters = {
    sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    permission_routes: state => state.permission.routes,
    limitAdd: state => state.control.limitAdd,
    chargeAdd: state => state.control.chargeAdd,
    pointAdd: state => state.control.pointAdd,
    pointTree: state => state.control.pointTree,
    panelID: state => state.control.panelID,
    imageDetailParams: state => state.control.imageDetailParams,
    finishPosition: state => state.control.finishPosition,
    robotLink: state => state.robot.robotLink,
    robotIp: state => state.robot.robotIp,
    robotSerial: state => state.robot.robotSerial,
    robotRtsp: state => state.robot.robotRtsp,
    flvUrl: state => state.robot.flvUrl,
    robotName: state => state.robot.robotName,
    robotState: state => state.robot.robotState,
    minioUrl: state => state.robot.minioUrl,
    mqUrl: state => state.robot.mqUrl,
    mqName: state => state.robot.mqName,
    mqPassword: state => state.robot.mqPassword,
    checked: state => state.robot.checked,
    mapLink: state => state.robot.mapLink,
    mapRobot: state => state.robot.mapRobot,
    mapName: state => state.robot.mapName,
    systemParam: state => state.robot.systemParam,
}
export default getters