<template>
    <el-row class="margin-b" type="flex" justify="space-between" >
        <el-col :span="spanNumber" :xs="24" class="title-style">
            <span>{{title}}</span>
        </el-col>
        <span class="back-click">
            <!--  icon="el-icon-close" -->
            <el-button class="come-back" @click="childMethod">返回上一页</el-button>
        </span>
    </el-row>
</template>

<script>
export default {
    name: 'DialogTitle',
    data() {
        return {
            title: this.titleParent
        }
    },
    props: {
        fatherMethod: {
            type: Function,
            default: null
        },
        titleParent: {
            type: String,
            default: null
        },
        spanNumber: {
            type: Number,
            default: 7
        }
    },
    watch: {
        titleParent: {
            handler(newVal,oldVal) {
                this.title = newVal
            },
            immediate: true,
        }
    },
    methods: {
        childMethod() {
            if (this.fatherMethod) {
                this.fatherMethod();
            }
        }
    }
}
</script>

<style lang="less" scoped>
// .el-dialog__wrapper {
//     .margin-b {
//         margin-bottom: 20px;
//     }
//     .title-style {
//         font-size: 20px;
//         line-height: 36px;
//         background-color: #367539;
//         color: honeydew;
//         text-align: center;
//     }
//     .back-click {
//         float: right;
//     }
    
// }
</style>