<template>
    <div>
        <svg-icon icon-class="warn"  @click="click"/>
    </div>
</template>

<script>

export default {
  name: 'SysWarn',
  methods:{
      click() {
          this.$router.push({ path:'/alarm/sys'  })
      }
  }
}
</script>

<style scoped>
/* .warn-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;;
  width: 25px;
  height: 25px;
  vertical-align: 10px;
} */
</style>