import { getRobot, getRobotState } from '@/api/robot/robot'
import { getMap, getMapContactState, getMapState } from '@/api/robot/map'
import { contactRosLink } from '@/utils/robotLink_rabbitMQ'
import { getSysParamsMapID } from "@/api/system/arcGIS"
import { setMap, setRobot, removeRobot, removeMap } from '@/utils/auth'

const robot = {
    state: {
        robotLink: '',
        robotIp: '',
        robotSerial: '',
        robotRtsp: '',
        robotName: '',
        robotState: '',
        minioUrl: '',
        mqUrl: '',
        mqName:'',
        mqPassword:'',
        checked: false,
        mapLink: '',
        mapRobot: '',
        mapName: '',
        systemParam: {},
        flvUrl: ''
    },

    mutations: {
        SET_ROBOT: (state, robotLink) => {
            state.robotLink = robotLink;
        },
        SET_ROBOTIP: (state, robotIp) => {
            state.robotIp = robotIp;
        },
        SET_ROBOTSERIAL: (state, robotSerial) => {
            state.robotSerial = robotSerial;
        },
        SET_ROBOTRTSP: (state, robotRtsp) => {
            state.robotRtsp = robotRtsp;
        },
        SET_ROBOTFLV: (state, flvUrl) => {
            state.flvUrl = flvUrl;
        },
        SET_ROBOTNAME: (state, robotName) => {
            state.robotName = robotName;
        },
        SET_ROBOTSTATE: (state, robotState) => {
            state.robotState = robotState;
        },
        SET_MINIO: (state, minioUrl) => {
            state.minioUrl = minioUrl;
        },
        SET_MQURL: (state, mqUrl) => {
            state.mqUrl = mqUrl;
        },
        SET_MQNAME: (state, mqName) => {
            state.mqName = mqName;
        },
        SET_MQPASSWORD: (state, mqPassword) => {
            state.mqPassword = mqPassword;
        },
        SET_CHECKED: (state, checked) => {
            state.checked = checked;
        },
        SET_MAP: (state, mapLink) => {
            state.mapLink = mapLink;
        },
        SET_MAPROOT: (state, mapRobot) => {
            state.mapRobot = mapRobot;
        },
        SET_MAPNAME: (state, mapName) => {
            state.mapName = mapName;
        },
        SET_SYSTEMPARAM: (state, systemParam) => {
            state.systemParam = systemParam;
        },
    },

    actions: {
        // 获取机器人信息
        GetRobot({ state, commit, dispatch }, robotLink) {
            return new Promise((resolve, reject) => {
                if (robotLink && robotLink != '') {
                    getRobot(robotLink).then(res => {
                        contactRosLink(res.data.ip, robotLink, res.data.ros, function(link) {
                            if (link === "未连接") {
                                // poseListener = undefined
                                dispatch('DeleteMapLink', '')
                                dispatch('DeleteRobotLink', link)
                                reject()
                            } else {
                                commit('SET_ROBOT', robotLink)
                                commit('SET_ROBOTIP', res.data.ip)
                                commit('SET_ROBOTRTSP', res.data.rtspUrl)
                                commit('SET_ROBOTFLV', res.data.flvUrl)
                                commit('SET_ROBOTSERIAL', res.data.serialNumber)
                                commit('SET_ROBOTNAME', res.data.robotName)
                                commit('SET_MINIO', res.data.minioUrl)
                                commit('SET_MQURL', res.data.mqUrl.split('--')[0])
                                commit('SET_MQNAME', res.data.mqUrl.split('--')[1])
                                commit('SET_MQPASSWORD', res.data.mqUrl.split('--')[2])
                                commit('SET_CHECKED', res.data.ros)
                                setRobot(robotLink)
                                resolve()
                            }
                        })
                    }).catch(error => {
                        reject(error)
                    })
                } else {
                    dispatch('DeleteRobotLink', '')
                    reject()
                }
            })
        },
        DeleteRobotLink({ state, commit, dispatch }, stateName) {
            poseListener = undefined
            commit('SET_ROBOT', '')
            commit('SET_ROBOTIP', '')
            commit('SET_ROBOTNAME', stateName)
            commit('SET_ROBOTRTSP', '')
            commit('SET_ROBOTFLV', '')
            commit('SET_ROBOTSTATE', '')
            commit('SET_ROBOTSERIAL', '')
            commit('SET_MINIO', '')
            commit('SET_MQURL', '')
            commit('SET_MQNAME', '')
            commit('SET_MQPASSWORD', '')
            commit('SET_CHECKED', false)
            removeRobot()
        },
        DeleteMapLink({ state, commit, dispatch }, stateName) {
            poseListener = undefined
            commit('SET_MAP', '')
            commit('SET_SYSTEMPARAM', {})
            commit('SET_MAPNAME', stateName)
            commit('SET_MAPROOT', '')
            removeMap()
        },
        async GetContactRobot({ state, commit, dispatch }, stateStyle) {
            return new Promise((resolve, reject) => {
                getRobotState().then(response => {
                    if (response === "认证错误") {
                        reject(response)
                            // } else if (response && response.code === "000000" && response.data.id !== null) {
                    } else if (response && response.code === "000000" && response.data.id !== null && state.robotLink === response.data.id && ros) {
                        setRobot(response.data.id)
                        getRobot(response.data.id).then(res => {
                            commit('SET_ROBOT', res.data.id)
                            commit('SET_ROBOTIP', res.data.ip)
                            commit('SET_ROBOTRTSP', res.data.rtspUrl)
                            commit('SET_ROBOTFLV', res.data.flvUrl)
                            commit('SET_ROBOTNAME', res.data.robotName)
                            commit('SET_ROBOTSERIAL', res.data.serialNumber)
                            commit('SET_MINIO', res.data.minioUrl)
                            commit('SET_MQURL', res.data.mqUrl.split('--')[0])
                            commit('SET_MQNAME', res.data.mqUrl.split('--')[1])
                            commit('SET_MQPASSWORD', res.data.mqUrl.split('--')[2])
                            commit('SET_CHECKED', res.data.ros)
                        })
                        resolve(response)
                    } else if (response && response.code === "000000" && response.data.id !== null && (!ros || state.robotLink !== response.data.id)) {
                        setRobot(response.data.id)
                        dispatch('GetRobot', response.data.id).then(() => {
                            resolve(response)
                        }).catch(() => {
                            reject("机器人")
                        })
                    } else {
                        dispatch('DeleteRobotLink', '')
                        dispatch('DeleteMapLink', '')
                        reject("机器人")
                    }
                }).catch(error => {
                    dispatch('DeleteRobotLink', '')
                    dispatch('DeleteMapLink', '')
                    reject(error)
                })
            })
        },
        async GetContactMap({ state, commit, dispatch }, stateStyle) {
            return new Promise((resolve, reject) => {
                getMapState().then(response => {
                    if (response === "认证错误") {
                        reject(response)
                    } else if (response && response.code === "000000" && response.data.id !== null) {
                        setMap(response.data.id)
                        getMap(response.data.id).then(res => {
                            if (res && res.code === "000000") {
                                commit('SET_MAP', res.data.id)
                                commit('SET_MAPROOT', res.data.rosMapName)
                                commit('SET_MAPNAME', res.data.mapName)
                                getSysParamsMapID({ mapId: response.data.id }).then(resp => {
                                    if (resp && resp.code === "000000" && resp.data !== null) {
                                        commit('SET_SYSTEMPARAM', resp.data)
                                    }
                                })
                            }
                        })
                        resolve(response)
                    } else {
                        dispatch('DeleteMapLink', '')
                        reject("地图")
                    }
                }).catch(error => {
                    dispatch('DeleteMapLink', '')
                    reject(error)
                })
            })
        },
        // 获取地图信息
        GetMap({ state, commit, dispatch }, mapLink) {
            return new Promise((resolve, reject) => {
                if (mapLink && mapLink != '') {
                    // 本地http运行，云端https运行  注解如下
                    /** 开始 */
                    getMap(mapLink).then(res => {
                        console.log('map exist');
                    /** 结束 */
                        var data = {
                                id: mapLink,
                                mapConnectionState: "CONNECTED"
                            }
                            //         commit('SET_SYSTEMPARAM', response.data)
                        getMapContactState(data).then(response => {
                            if (response && response.code === "000000") {
                                commit('SET_MAP', mapLink)
                                // 本地http运行，云端https运行 修改如下两个变量为''
                                commit('SET_MAPROOT', res.data.rosMapName)
                                commit('SET_MAPNAME', res.data.mapName)
                                setMap(mapLink)
                                resolve()
                            }
                        })
                    /** 开始 */
                    }).catch(error => {
                        reject(error)
                    })
                    /** 结束 */
                } else {
                    dispatch('DeleteMapLink', '')
                    reject()
                }
            })
        },
    }
}

export default robot